import image from '../../images/F86B1DF9-BED5-428a-84BD-FBCCB78D9740.png';
import getBG from '../../utils/utils';
export default {
    name: 'qianlima-news',
    data() {
        return {
            image: image,
            news: [],
            backgroundImage: '',
            total: '',
            pageNum: 1,
            pageCount: 10
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getNews();
            this.backgroundImage = await getBG.call(this, 'QianlimaNews');
        },
        // 查询动态
        getNews() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'QianlimaNews',
                    pageNum: this.pageNum,
                    pageCount: this.pageCount
                })
                .then(res => {
                    this.news = res.data.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                    this.total = res.total;
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        go(path, item) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: item.id
                }
            });

            window.open(href, '_blank');
        },
        currentChange(e) {
            this.pageNum = e;
            console.log(this.pageNum);
            this.getNews();
        },
        prev() {
            this.pageNum--;
            this.getNews();
            console.log(this.pageNum);
        },
        next() {
            this.pageNum++;
            this.getNews();
            console.log(this.pageNum);
        }
    }
};
