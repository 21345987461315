import { render, staticRenderFns } from "./corporate-culture-detail.vue?vue&type=template&id=472d3f3b&"
import script from "./corporate-culture-detail.js?vue&type=script&lang=js&"
export * from "./corporate-culture-detail.js?vue&type=script&lang=js&"
import style0 from "./corporate-culture-detail.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports