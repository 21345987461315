// 查背景图片
export default function getBG(purposeType) {
    return new Promise((resolve, reject) => {
        this.$http
            .fetchData('contentManage/getContentManageList', {
                contentType: 'BackgroundImage',
                purposeType: purposeType
            })
            .then(res => {
                resolve(this.$config.baseUrl + res[0].image);
            })
            .catch(err => {
                this.$message({
                    message: err,
                    type: 'error'
                });
            });
    });
}
