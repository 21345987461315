// import config from '../../../config/config';

export default {
    name: 'index',
    data() {
        return {
            slideshow: [],
            input: {},
            companyInfo: {},
            contractsName: '',
            mobile: '',
            companyName: '',
            address: '',
            products: '',
            loading: false,
            carouselLoading: true,
            pageMiddleImage: ''
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getSlideshow();
            this.getCompanyInfo();
            this.getProducts();
            this.getPageMiddleImage();
        },
        myBrowser() {
            var userAgent = navigator.userAgent;
            var isOpera = userAgent.indexOf('Opera') > -1;
            var isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera;
            var isEdge = userAgent.indexOf('Edge') > -1;
            var isFF = userAgent.indexOf('Firefox') > -1;
            var isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1;
            var isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1;

            if (isIE) {
                return 'IE';
            }

            if (isOpera) {
                return 'Opera';
            }
            if (isEdge) {
                return 'Edge';
            }
            if (isFF) {
                return 'FF';
            }
            if (isSafari) {
                return 'Safari';
            }
            if (isChrome) {
                return 'Chrome';
            }
            console.log(isOpera, isEdge, isFF, isSafari, isChrome, isIE);
        },
        glide() {
            const target = document.getElementsByClassName('companyInfo')[0].offsetTop;
            var ie = this.myBrowser();
            console.log(ie);
            if (ie !== 'Chrome') {
                console.log(target);
                let step = 0;
                do {
                    step += 5;
                    window.scrollTo(0, step);
                    console.log(target);
                    console.log(step);
                } while (step <= target);
            } else {
                window.scrollTo({
                    top: target,
                    behavior: 'smooth'
                });
            }
        },
        // 查询首页中间图片
        getPageMiddleImage() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'HomePageMiddleImage' })
                .then(res => {
                    this.pageMiddleImage = { ...res[0], image: this.$config.baseUrl + res[0].image };
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 查询轮播图
        getSlideshow() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'HomePageImage' })
                .then(res => {
                    this.slideshow = res.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 查询公司简介
        getCompanyInfo() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'HomePageCompanyInfo' })
                .then(res => {
                    console.log(res);
                    this.companyInfo = { ...res[0], image: this.$config.baseUrl + res[0].image };
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 查询产品
        getProducts() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'HomePageProducts' })
                .then(res => {
                    console.log(res);
                    this.products = res.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 提交渠道合作
        addChannelCooperation() {
            this.loading = true;
            this.$http
                .fetchData('channelCooperation/addChannelCooperation', {
                    address: this.address,
                    contractsName: this.contractsName,
                    mobile: this.mobile,
                    companyName: this.companyName
                })
                .then(res => {
                    this.address = '';
                    this.contractsName = '';
                    this.mobile = '';
                    this.companyName = '';
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    });
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        go(path) {
            const { href } = this.$router.resolve({
                path: path
            });

            window.open(href, '_blank');
        },
        goProductDetail(item) {
            const { href } = this.$router.resolve({
                path: item.address.split('/')[1]
            });

            window.open(href, '_blank');
        },
        goProduct(item) {
            console.log(item);
            if (item.content && !item.address) {
                const { href } = this.$router.resolve({
                    path: 'OpenPlatform',

                    query: {
                        id: item.id
                    }
                });

                window.open(href, '_blank');
                return;
            }
            const { href } = this.$router.resolve({
                path: item.address.split('/')[1]
            });

            window.open(href, '_blank');
        },
        async queryTrainingVideo() {
            console.log('查询培训视频');
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'homePageTrainingVideo'
                })
                .then(res => {
                    if (res && res.length > 0 && res[0].simpleIntroduction && res[0].simpleIntroduction.length > 0) {
                        const href = res[0].simpleIntroduction;
                        window.open(href, '_blank');
                    } else {
                        this.$router.push({ path: 'TrainingVideo' });
                    }
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
