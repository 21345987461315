import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import Index from '../views/home/index/Index.vue';
import CloudProduct from '../views/cloud-product/Product.vue';
import MobileProducts from '../views/iphone-product/Product.vue';
import CSProducts from '../views/cs-product/Product.vue';
import Profile from '../views/company-profile/company-profile.vue';
import TalentPlan from '../views/talent-plan/talent-plan.vue';
import CorporateCulture from '../views/corporate-culture/corporate-culture.vue';
import CustomerCase from '../views/customer-case/customer-case.vue';
import ListCustomers from '../views/list-customers/list-customers.vue';
import QianlimaNews from '../views/qianlima-news/qianlima-news.vue';
import DataDownload from '../views/data-download/data-download.vue';
import ContactIformation from '../views/contact-iformation/contact-iformation.vue';
import DemandFeedback from '../views/demand-feedback/demand-feedback.vue';
import ProjectFlowChart from '../views/project-flowChart/project-flowChart.vue';
import CloudSystemTrial from '../views/cloud-system-trial/cloud-system-trial.vue';
import Feedback from '../views/feed-back/feed-back.vue';
import PopularFAQs from '../views/popular-FAQs/popular-FAQs.vue';
import ChannelCooperation from '../views/channel-cooperation/channel-cooperation.vue';
import ServiceSystem from '../views/service-system/service-system.vue';
import CorporateCultureDetail from '../views/corporate-culture-detail/corporate-culture-detail.vue';
import productDetail from '../views/product-detail/product-detail.vue';
import CustomerCaseDetail from '../views/customer-case-detail/customer-case-detail.vue';
import newsDetail from '../views/news-detail/news-detail.vue';
import FAQSDetail from '../views/FAQs-detail/FAQs-detail.vue';
import HoursService from '../views/hours-service/hours-service.vue';
import AddService from '../views/added-service/added-service.vue';
import RemoteTraining from '../views/remote-training/remote-training.vue';
import TrainingVideo from '../views/training-video/training-video.vue';
import OpenPlatform from '../views/open-platform/open-platform.vue';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/Home'
    },
    {
        path: '/Home',
        name: 'Home',
        component: Home,
        redirect: '/Home/Index',
        children: [
            { path: 'Index', component: Index },
            { path: 'CloudProducts', component: CloudProduct },
            { path: 'MobileProducts', component: MobileProducts },
            { path: 'CSProducts', component: CSProducts },
            { path: 'WanXunInfo', component: Profile },
            { path: 'TalentPlan', component: TalentPlan },
            { path: 'AboutWanxun', component: CorporateCulture },
            { path: 'CustomerCase', component: CustomerCase },
            { path: 'ListCustomers', component: ListCustomers },
            { path: 'QianlimaNews', component: QianlimaNews },
            { path: 'DataDownload', component: DataDownload },
            { path: 'ContactIformation', component: ContactIformation },
            { path: 'DemandFeedback', component: DemandFeedback },
            { path: 'ProjectFlowChart', component: ProjectFlowChart },
            { path: 'CloudSystemTrial', component: CloudSystemTrial },
            { path: 'Feedback', component: Feedback },
            { path: 'PopularFAQs', component: PopularFAQs },
            { path: 'ChannelCooperation', component: ChannelCooperation },
            { path: 'ServiceSystem', component: ServiceSystem },
            { path: 'CorporateCultureDetail', component: CorporateCultureDetail },
            { path: 'productDetail', component: productDetail },
            { path: 'CustomerCaseDetail', component: CustomerCaseDetail },
            { path: 'newsDetail', component: newsDetail },
            { path: 'FAQSDetail', component: FAQSDetail },
            { path: 'HoursService', component: HoursService },
            { path: 'AddService', component: AddService },
            { path: 'RemoteTraining', component: RemoteTraining },
            { path: 'TrainingVideo', component: TrainingVideo },
            { path: 'OpenPlatform', component: OpenPlatform }
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
];

const router = new VueRouter({
    // mode: 'history',
    routes
});

export default router;
