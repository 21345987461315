import getBG from '../../utils/utils';
export default {
    name: 'contact-iformation',
    data() {
        return {
            activeMarker: {},
            zooms: [4, 5],
            markers: [], // 定位
            center: [104.094034, 30.624202], // 地图初始化视图中心点
            backgroundImage: '',
            plugin: [
                // 这里放扩展的插件
                // {
                //     pName: 'Scale', // 比例尺
                //     events: {
                //         init(instance) {
                //             console.log(instance);
                //         }
                //     }
                // }
            ]
        };
    },
    mounted() {},
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getCases();
            this.backgroundImage = await getBG.call(this, 'ContactIformation');
        },
        // 查联系方式
        getCases() {
            this.$http
                .fetchData('companyInfo/getCompanyInfoList', {})
                .then(res => {
                    this.markers = res.map((item, i) => {
                        item.position = [item.longitude, item.latitude];
                        item.events = {
                            mouseover: () => {
                                this.activeMarker = this.markers[i];
                            }
                        };
                        return item;
                    });
                    this.activeMarker = this.markers[0];
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
