import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './assets/icon/iconfont.css';
import './assets/fonts/font.css';
import router from './router';
import http from './api/index';
import VueAMap from 'vue-amap';
Vue.use(ElementUI);
// 高德地图
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: '42b1c213b74b4a1c42807db0c8bddb7f',
    plugin: [
        // 这里根据自己项目按需引入插件
        'Autocomplete',
        'PlaceSearch',
        'Scale',
        'OverView',
        'ToolBar',
        'MapType',
        'PolyEditor',
        'AMap.CircleEditor'
    ],
    v: '1.4.4'
});
Vue.config.productionTip = false;
Vue.prototype.$http = http;
// const config = require('../src/static/config/config.json');

Vue.prototype.$config = window.config;
const setPVUV = (content, id) => {
    console.log('进入系统的浏览器地址：', window?.location?.href);
    if (window.location && window.location.href && window.location.href.startsWith('http://www.armitage.com.cn')) {
        window.location.href = 'https://www.armitage.com.cn';
        // window.location.href = 'https://www.baidu.com';
    }
    http.fetchData('viewscount/addViewscount', { content, id })
        .then(res => {
            console.log('统计加1');
        })
        .catch(err => {
            err();
        });
};
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
    console.log(to, from, to.query.id);
    setPVUV(to.path.split('/')[to.path.split('/').length - 1], to.query.id);
});

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
