import getBG from '../../utils/utils';

export default {
    name: 'talent-plan',
    data() {
        return {
            talentPlanInfo: {},
            jobs: [],
            backgroundImage: ''
        };
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        async init() {
            // this.getTalentPlanInfo();
            this.getJobs();
            this.backgroundImage = await getBG.call(this, 'TalentPlan');
        },
        // // 查询人才计划简介
        // getTalentPlanInfo() {
        //     this.$http
        //         .fetchData('contentManage/getContentManageList', {
        //             contentType: 'TalentPlan',
        //             purposeType: 'explainInfo'
        //         })
        //         .then(res => {
        //             console.log(res);
        //             this.talentPlanInfo = { ...res[0], image: this.$config.baseUrl + res[0].image };
        //         })
        //         .catch(err => {
        //             this.$message({
        //                 message: err,
        //                 type: 'error'
        //             });
        //         });
        // },
        // 查询招聘列表
        getJobs() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'TalentPlan',
                    purposeType: 'talentPlanInfo'
                })
                .then(res => {
                    console.log(res);
                    this.jobs = res;
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
