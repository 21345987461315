import axios from 'axios';
// import { showFullScreenLoading, tryHideFullScreenLoading } from '../components/common/loading';
// const config = require('../static/config/config.json');
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: window.config.baseUrl + '/',
    // baseURL: 'http://localhost:8081/webserver/',
    timeout: 60000
});
service.interceptors.request.use(
    config => {
        // showFullScreenLoading();
        return config;
    },
    error => {
        console.log(error);
        // tryHideFullScreenLoading();
        return Promise.reject(new Error(error));
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200 && response.data.errCode === '0') {
            console.log(response);
            if (
                response.config.data &&
                JSON.parse(response.config.data).pageNum &&
                JSON.parse(response.config.data).pageCount
            ) {
                return response.data;
            } else {
                return response.data.data;
            }
            // tryHideFullScreenLoading();
        } else {
            // tryHideFullScreenLoading();
            return Promise.reject(new Error(response.data.errText));
        }
    },
    error => {
        console.log(error);
        // tryHideFullScreenLoading();
        return Promise.reject(new Error(error));
    }
);

export default service;
