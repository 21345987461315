import getBG from '../../utils/utils';

export default {
    name: 'popular-FAQs',
    data() {
        return {
            FAQs: [],
            backgroundImage: '',
            total: '',
            pageNum: 1,
            pageCount: 10
        };
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        async init() {
            this.getFAQs();
            this.backgroundImage = await getBG.call(this, 'PopularFAQs');
        },
        // 查询常见问题
        getFAQs() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'PopularFAQs',
                    pageNum: this.pageNum,
                    pageCount: this.pageCount
                })
                .then(res => {
                    console.log(res);
                    this.FAQs = res.data;
                    this.total = res.total;
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        go(path, item) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: item.id
                }
            });

            window.open(href, '_blank');
        },
        currentChange(e) {
            this.pageNum = e;
            console.log(this.pageNum);
            this.getFAQs();
        },
        prev() {
            this.pageNum--;
            this.getFAQs();
            console.log(this.pageNum);
        },
        next() {
            this.pageNum++;
            this.getFAQs();
            console.log(this.pageNum);
        }
    }
};
