import getBG from '../../utils/utils';

export default {
    name: 'open-platform',
    data() {
        return {
            backgroundImage: '',
            content: ''
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.backgroundImage = await getBG.call(this, 'AddService');
            this.getContext();
        },
        // 查询富文本信息
        getContext() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    id: this.$route.query.id
                })
                .then(res => {
                    console.log(res);
                    this.content = res[0].content;
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
