import getBG from '../../utils/utils';
export default {
    name: 'company-profile',
    data() {
        return {
            companyDetail: [],
            backgroundImage: ''
        };
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        async init() {
            this.getCompanyDetail();
            console.log(await getBG.call(this, 'WanXunInfo'));
            this.backgroundImage = await getBG.call(this, 'WanXunInfo');
        },
        // 查询公司简介
        getCompanyDetail() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'WanXunInfo',
                    purposeType: 'DetailPage'
                })
                .then(res => {
                    this.companyDetail = res.map(item => {
                        return { ...item, image: this.$config.baseUrl + item.image };
                    });
                    console.log(this.companyDetail);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
