import getBG from '../../utils/utils';

export default {
    name: 'hours-service',
    data() {
        return {
            backgroundImage: '',
            content: ''
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.backgroundImage = await getBG.call(this, 'Service24Hours');
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'homePage24HoursService'
                })
                .then(res => {
                    this.content = res[0].content;
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
