import getBG from '../../utils/utils';

export default {
    name: 'feed-back',
    data() {
        return {
            demand: {
                systemType: []
            },
            rules: {
                contactsMan: [{ required: true, message: '联系人' }],
                systemType: [{ required: true, message: '系统类型' }],
                hotelName: [{ required: true, message: '酒店名称' }],
                mobile: [{ required: true, message: '联系人电话' }]
            },
            systems: [],
            backgroundImage: '',
            loading: false
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getSystemType();
            this.backgroundImage = await getBG.call(this, 'Feedback');
        },
        // 查询系统类型
        getSystemType() {
            this.$http
                .fetchData('codes/getCodesList', {
                    category: 'SystemType'
                })
                .then(res => {
                    console.log(res);
                    this.systems = res;
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 提交意见反馈
        addFeedbackInfo() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    const arr = [];
                    if (this.demand.systemType.length > 0) {
                        this.demand.systemType.forEach(item => {
                            this.systems.forEach(system => {
                                if (item === system.name) {
                                    item = system.code;
                                    arr.push(system.code);
                                }
                            });
                        });
                        this.demand.systemType = arr.join(',');
                    } else {
                        this.demand.systemType = arr.join(',');
                    }
                    this.$http
                        .fetchData('feedbackInfo/addFeedbackInfo', {
                            ...this.demand,
                            systemType: this.demand.systemType
                        })
                        .then(res => {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                            this.loading = false;
                            this.demand = {
                                systemType: []
                            };
                        })
                        .catch(err => {
                            console.log(err);
                            this.$message({
                                message: err,
                                type: 'error'
                            });
                        });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 重置
        reset() {
            this.demand = {
                systemType: []
            };
        }
    }
};
