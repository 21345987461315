var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"index"},[_c('el-carousel',{attrs:{"height":"200px"}},[_vm._l((_vm.slideshow),function(item){return _c('el-carousel-item',{key:item.src,attrs:{"direction":"horizontal"}},[_c('el-image',{attrs:{"src":item.image,"fit":"cover"},on:{"click":function($event){return _vm.goProductDetail(item)}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.carouselLoading),expression:"carouselLoading"}],staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"})])],1)}),_c('div',{staticClass:"bannernext",on:{"click":_vm.glide}},[_c('div',{staticClass:"bannerimg"},[_c('img',{attrs:{"src":require("../../../images/mouse.png")}})])])],2),_c('div',{staticClass:"companyInfo"},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":_vm.companyInfo.image}})]),_c('div',{staticClass:"info"},[_c('h2',[_vm._v(_vm._s(_vm.companyInfo.title))]),_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(_vm.companyInfo.simpleIntroduction)+" ")]),_c('p',{staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.go('/Home/WanXunInfo')}}},[_vm._v("详情")])])])]),_c('div',{staticClass:"product"},[_c('div',{staticClass:"pic"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.pageMiddleImage.image}})]),_c('div',{staticClass:"info"},[_c('ul',_vm._l((_vm.products),function(item){return _c('li',{key:item.id},[_c('div',{staticClass:"link",on:{"click":function($event){return _vm.goProduct(item)}}},[_c('img',{staticStyle:{"width":"50%"},attrs:{"src":item.image}}),_c('p',[_vm._v(_vm._s(item.title))])]),_c('p',[_vm._v(" "+_vm._s(item.simpleIntroduction)+" ")])])}),0)])]),_c('section',{staticClass:"contact"},[_c('div',[_vm._v("如何才能在竞争的浪潮中突围而出？")]),_c('div',[_vm._v("云系统能够为酒店业保驾护航！")]),_c('el-button',{on:{"click":function () {
                    this$1.$router.push({ path: 'CloudSystemTrial' });
                }}},[_vm._v("联系我们")])],1),_c('section',{staticClass:"serve"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('../../../images/slides/2.jpg')}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("6大服务平台 全方位保障酒店用户需求")]),_c('ul',[_c('li',{on:{"click":function () {
                            this$1.$router.push({ path: 'HoursService' });
                        }}},[_vm._m(0)]),_c('li',{on:{"click":function () {
                            this$1.$router.push({ path: 'Feedback' });
                        }}},[_vm._m(1)]),_c('li',[_c('div',{on:{"click":function () {
                                this$1.$router.push({ path: 'PopularFAQs' });
                            }}},[_c('i',{staticClass:"iconfont icon-changjianwenti"}),_c('p',[_vm._v("常见问题")])])]),_c('li',{on:{"click":function () {
                            this$1.$router.push({ path: 'AddService' });
                        }}},[_vm._m(2)]),_c('li',{on:{"click":function () {
                            this$1.$router.push({ path: 'RemoteTraining' });
                        }}},[_vm._m(3)]),_c('li',{on:{"click":function($event){return _vm.queryTrainingVideo()}}},[_vm._m(4)])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"iconfont icon-huaban"}),_c('p',[_vm._v("7×24小时服务")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"iconfont icon-yijianfankui"}),_c('p',[_vm._v("意见反馈")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"iconfont icon-fuwuguanli"}),_c('p',[_vm._v("增值服务")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"iconfont icon-yuanchengguanli"}),_c('p',[_vm._v("客户远程培训")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"iconfont icon-shipin"}),_c('p',[_vm._v("培训视频教程")])])}]

export { render, staticRenderFns }