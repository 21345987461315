import image from '../../images/F86B1DF9-BED5-428a-84BD-FBCCB78D9740.png';
import getBG from '../../utils/utils';
export default {
    name: 'list-customers',
    data() {
        return {
            image: image,
            customerslist: [],
            backgroundImage: '',
            customerType: [
                { code: 'groupSystem', name: '酒店连锁', customerList: [] },
                { code: 'School', name: '高等院校', customerList: [] },
                { code: 'SPASystem', name: '温泉水疗', customerList: [] },
                { code: 'FBSystem', name: '社会餐饮', customerList: [] },
                { code: 'SingleHotelSystem', name: '单体酒店', customerList: [] },
                { code: 'OtherSystem', name: '其它业态', customerList: [] }
            ]
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getCustomerslist();
            this.backgroundImage = await getBG.call(this, 'ListCustomers');
        },
        // 查询客户名录
        getCustomerslist() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'ListCustomers' })
                .then(res => {
                    res.forEach(item => {
                        item.image = this.$config.baseUrl + item.image;
                        this.customerType.forEach(data => {
                            if (data.code === item.purposeType) {
                                data.customerList.push(item);
                            }
                        });
                    });
                    // this.customerslist = res.map(item => {
                    //     item.image = this.$config.baseUrl + item.image;
                    //     return item;
                    // });
                    console.log(this.customerType);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
