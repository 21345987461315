export default {
    name: 'Home',
    data() {
        return {
            menus: [],
            isTop: true,
            products: [],
            news: [],
            QRcodes: [],
            contactInfo: {},
            activePaht: ''
        };
    },
    created() {
        this.$http.fetchData('menu/getMenu').then(res => {
            this.menus = res.map(menu => {
                if (menu.childMenu) {
                    menu.childMenu.forEach(item => {
                        item.routePath = 'Home/' + item.routePath;
                    });
                }
                return menu;
            });
            console.log(res);
            console.log(this.menus);
            console.log(this.$router.history.current.path);
            if (this.$router.history.current.path === '/Home/Index') {
                this.activePaht = '/Home';
                return;
            }
            let flag = false;
            this.menus.forEach(menu => {
                menu.childMenu &&
                    menu.childMenu.forEach(item => {
                        if (this.$router.history.current.path === '/' + item.routePath) {
                            flag = true;
                            this.activePaht = this.$router.history.current.path;
                        }
                    });
            });
            if (!flag) {
                this.activePaht = '/Home';
            }
        });
        this.$http.fetchData('contentManage/getContentManageList', { contentType: 'QianlimaNews' }).then(res => {
            this.news = res;
            this.news = this.news.map(item => {
                if (item.title && item.title.length > 15) {
                    item.title = item.title.substr(0, 15) + '...';
                }
                return item;
            });
        });
        this.$http.fetchData('contentManage/getContentManageList', { contentType: 'BestProduct' }).then(res => {
            this.products = res;
        });
        this.$http
            .fetchData('contentManage/getContentManageList', { contentType: 'HomePageContractInfo' })
            .then(res => {
                console.log(res);
                this.QRcodes = res.map(item => {
                    item.image = this.$config.baseUrl + item.image;
                    if (item.address) {
                        this.contactInfo = item;
                    }
                    return item;
                });
            });
    },
    mounted() {
        // if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        //     window.attachEvent('onscroll', this.scrollEvent);
        // } else {
        //     window.addEventListener('scroll', this.scrollEvent);
        // }
    },
    methods: {
        go(path, item) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: item.id,
                    purposeType: item.contentType
                }
            });

            window.open(href, '_blank');
        }
    }
};
