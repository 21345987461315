import getBG from '../../utils/utils';

export default {
    name: 'project-flowChart',
    data() {
        return {
            PM: [],
            backgroundImage: '',
            announcements: {}
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getPM();
            this.getAnnouncements();
            this.backgroundImage = await getBG.call(this, 'ProjectFlowChart');
        },
        // 查实施流程
        getPM() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'ProjectFlowChart',
                    purposeType: 'ProjectFlow'
                })
                .then(res => {
                    this.PM = res.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        // 查询注意事项
        getAnnouncements() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'ProjectFlowChart',
                    purposeType: 'ProjectAttention'
                })
                .then(res => {
                    console.log(res);
                    this.announcements = { ...res[0], image: this.$config.baseUrl + res[0].image };
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        }
    }
};
