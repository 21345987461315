import { render, staticRenderFns } from "./hours-service.vue?vue&type=template&id=1312e3e8&"
import script from "./hours-service.js?vue&type=script&lang=js&"
export * from "./hours-service.js?vue&type=script&lang=js&"
import style0 from "./hours-service.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports