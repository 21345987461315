import getBG from '../../utils/utils';
export default {
    name: 'service-system',
    data() {
        return {
            content: '',
            backgroundImage: ''
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getContentManageList();
            this.backgroundImage = await getBG.call(this, 'ServiceSystem');
        },
        getContentManageList() {
            this.$http
                .fetchData('contentManage/getContentManageList', { contentType: 'ServiceSystem' })
                .then(res => {
                    console.log(res);
                    this.content = res[0].content;
                })
                .catch(err => {
                    this.$message.error(err);
                });
        }
    }
};
