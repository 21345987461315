import image from '../../images/F86B1DF9-BED5-428a-84BD-FBCCB78D9740.png';
import getBG from '../../utils/utils';

export default {
    name: 'customer-case',
    data() {
        return {
            image: image,
            caseList: [],
            backgroundImage: '',
            total: 0,
            pageNum: 1,
            pageCount: 9
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getCases();
            this.backgroundImage = await getBG.call(this, 'CustomerCase');
        },
        // 查询客户案例
        getCases() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'CustomerCase',
                    pageNum: this.pageNum,
                    pageCount: this.pageCount
                })
                .then(res => {
                    this.caseList = res.data.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                    this.total = res.total;
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        mouseOver(e) {
            console.log(e);
            console.log('mouseOver', e.target.className);
            if (e.target.className === 'casetext') {
                e.target.style.opacity = 1;
                e.target.parentNode.children[0].style.transform = 'scale(1.08)';
            }
            e.stopPropagation();
        },
        mouseOut(e) {
            console.log('mouseOut', e.target.className);
            if (e.target.className === 'casetext') {
                e.target.style.opacity = 0;
                e.target.parentNode.children[0].style.transform = 'scale(1)';
            }
            e.stopPropagation();
        },
        go(path, item) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: item.id
                }
            });

            window.open(href, '_blank');
        },
        currentChange(e) {
            this.pageNum = e;
            console.log(this.pageNum);
            this.getCases();
        },
        prev() {
            this.pageNum--;
            this.getCases();
            console.log(this.pageNum);
        },
        next() {
            this.pageNum++;
            this.getCases();
            console.log(this.pageNum);
        }
    }
};
