import getBG from '../../utils/utils';

export default {
    name: 'corporate-culture',
    data() {
        return {
            blogs: [],
            backgroundImage: '',
            total: 0,
            pageNum: 1,
            pageCount: 10
        };
    },
    created() {
        this.init();
    },
    mounted() {},
    methods: {
        async init() {
            this.getCorporateCulture();
            this.backgroundImage = await getBG.call(this, 'AboutWanxun');
        },
        // 查企业文化
        getCorporateCulture() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'AboutWanxun',
                    pageNum: this.pageNum,
                    pageCount: this.pageCount
                })
                .then(res => {
                    this.blogs = res.data.map(item => {
                        item.createTime = item.createTime.replace(/-/g, '/');
                        return item;
                    });
                    this.total = res.total;
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        go(path, id) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: id
                }
            });
            console.log(href);
            window.open(href, '_blank');
        },
        currentChange(e) {
            this.pageNum = e;
            console.log(this.pageNum);
            this.getCorporateCulture();
        },
        prev() {
            this.pageNum--;
            this.getCorporateCulture();
            console.log(this.pageNum);
        },
        next() {
            this.pageNum++;
            this.getCorporateCulture();
            console.log(this.pageNum);
        }
    }
};
