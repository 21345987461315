import getBG from '../../utils/utils';

export default {
    name: 'data-download',
    data() {
        return {
            backgroundImage: '',
            data: []
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getData();
            this.backgroundImage = await getBG.call(this, 'DataDownload');
        },
        // 查询下载产品
        getData() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'DataDownload'
                })
                .then(res => {
                    this.data = res.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        mouseOver(e) {
            console.log(e);
            console.log('mouseOver', e.target.className);
            if (e.target.className === 'casetext') {
                e.target.style.opacity = 1;
                e.target.parentNode.children[0].style.transform = 'scale(1.08)';
            }
            e.stopPropagation();
        },
        mouseOut(e) {
            console.log('mouseOut', e.target.className);
            if (e.target.className === 'casetext') {
                e.target.style.opacity = 0;
                e.target.parentNode.children[0].style.transform = 'scale(1)';
            }
            e.stopPropagation();
        }
    }
};
