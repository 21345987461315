import getBG from '../../utils/utils';

export default {
    name: 'cs-product',
    data() {
        return {
            products: [],
            backgroundImage: '',
            headerImage: ''
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getCSProduct();
            this.backgroundImage = await getBG.call(this, 'CSProducts');
            this.headerImage = await getBG.call(this, 'Products');
        },
        // 查云产品
        getCSProduct() {
            this.$http
                .fetchData('contentManage/getContentManageList', {
                    contentType: 'CSProducts'
                })
                .then(res => {
                    this.products = res.map(item => {
                        item.image = this.$config.baseUrl + item.image;
                        return item;
                    });
                    console.log(res);
                })
                .catch(err => {
                    this.$message({
                        message: err,
                        type: 'error'
                    });
                });
        },
        go(path, item) {
            const { href } = this.$router.resolve({
                path: path,
                query: {
                    id: item.id,
                    purposeType: 'CSProducts'
                }
            });

            window.open(href, '_blank');
        }
    }
};
