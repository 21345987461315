import request from '../utils/request';

const fetchData = (url, params) => {
    return request({
        url: url,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export default {
    fetchData
};
